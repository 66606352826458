export const generateWhatsAppUrl = (countryCode, phoneNumber, message) => {
  const cleanedPhoneNumber = phoneNumber.replace(/\D/g, ''); // Removes non-digits from the phone number
  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
  return isMobile
    ? `whatsapp://send/?phone=${countryCode}${cleanedPhoneNumber}&text=${encodeURIComponent(message)}`
    : `https://web.whatsapp.com/send?phone=${countryCode}${cleanedPhoneNumber}&text=${encodeURIComponent(message)}`;

  // return isMobile
    // ? `https://wa.me/${countryCode}${cleanedPhoneNumber}?text=${encodeURIComponent(message)}`
//whatsapp://send/?phone=${countryCode}${cleanedPhoneNumber}&text=${encodeURIComponent(message)}
//https://api.whatsapp.com/send?phone=${countryCode}${cleanedPhoneNumber}&text=${encodeURIComponent(message)}
    // : `https://web.whatsapp.com/send?phone=${countryCode}${cleanedPhoneNumber}&text=${encodeURIComponent(message)}`;
};