import { useState } from 'react';
import { generateSmsUrl } from '../utils/smsUrlGenerator';
import { generateTelegramUrl } from '../utils/telegramUrlGenerator';
import { validateInputs } from '../utils/validation';
import { generateWhatsAppUrl } from '../utils/whatsappUrlGenerator';

const useMessageSender = () => {
  const [countryCode, setCountryCode] = useState('+91');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');

  const sendWhatsAppMessage = () => {
    const validationError = validateInputs(phoneNumber, message);
    if (validationError) {
      setError(validationError);
      return;
    }

    const whatsappUrl = generateWhatsAppUrl(countryCode, phoneNumber, message);
    window.open(whatsappUrl, '_blank');
    setError('');
  };

  const sendTelegramMessage = () => {
    const validationError = validateInputs(phoneNumber, message);
    if (validationError) {
      setError(validationError);
      return;
    }

    const telegramUrl = generateTelegramUrl(phoneNumber, message);
    window.open(telegramUrl, '_blank');
    setError('');
  };

  const sendSmsMessage = () => {
    const validationError = validateInputs(phoneNumber, message);
    if (validationError) {
      setError(validationError);
      return;
    }

    const smsUrl = generateSmsUrl(phoneNumber, message);
    window.open(smsUrl, '_blank');
    setError('');
  };

  return {
    countryCode,
    phoneNumber,
    message,
    error,
    setCountryCode,
    setPhoneNumber,
    setMessage,
    sendWhatsAppMessage,
    sendTelegramMessage,
    sendSmsMessage
  };
};

export default useMessageSender;
