import { Globe } from 'lucide-react';
import React from 'react';
import { countryCodes } from '../constants/countryCodes';

const CountryCodeSelect = ({ value, onChange, phoneInput }) => {
  return (
    <div>
      <label className="block text-md font-medium text-gray-700 mb-2 flex items-center">
        <Globe size={16} className="mr-2" style = {{color:"5e1c57" }}/> 
        Country Code
      </label>
      <select
        value={value}
        onChange={(e) => {
          onChange(e.target.value); // Update the country code
          phoneInput(''); // Reset the phone number
        }}
        className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-green-500 focus:border-transparent"
      >
        {countryCodes.map((country) => (
          <option key={country.code} value={country.code}>
            {country.country} ({country.code})
          </option>
        ))}
      </select>
    </div>
  );
};

export default CountryCodeSelect;