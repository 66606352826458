import { MessageSquare } from 'lucide-react';
import React from 'react';

const MessageInput = ({ value = '', onChange }) => {
  return (
    <div>
      <label className="block text-md font-medium text-gray-700 mb-2 flex items-center">
        <MessageSquare size={16} className="mr-2" style = {{color:"5e1c57" }} />
        Message (optional)
      </label>
      <textarea
        value={value}
        onChange={(e) => onChange(e.target.value)}
        rows={4}
        placeholder="Type your message here (optional)"
        className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-green-500 focus:border-transparent"
      />
    </div>
  );
};

export default MessageInput;