import { Phone } from 'lucide-react';
import React from 'react';
const PhoneNumberInput = ({ value, onChange, countryCode }) => {
  // Mapping of country codes to required phone number lengths
  const phoneNumberLengths = {
    '+91': 10, // India
    '+1': 10,  // USA
    '+44': 10, // UK
    '+86': 11, // China
    '+81': 10, // Japan
    '+49': 10, // Germany
    '+7': 10, // Russia
    '+55': 9, // Brazil
    '+61': 9,  // Australia
    '+33': 10,  // France
    '+65': 8,  // Singapore
    '+974': 6 // Qatar 
  };

  // Determine the maximum length for the current country code
  const maxLength = phoneNumberLengths[countryCode] || 10; // Default to 10 if not found

  console.log("maxLength", maxLength)

  const handlePhoneNumberChange = (e) => {
    const phone = e.target.value;
    console.log("phone", phone)
    // Allow only numeric input and enforce length
    const numericValue = phone.replace(/\D/g, ''); // Remove non-digit characters
    const trimmedValue = numericValue.slice(0, maxLength); // Trim to maxLength
    console.log("numericValue", numericValue)
    console.log("trimmedValue", trimmedValue)
    console.log("value", value)

    // Update parent state with restricted value
    if (value !== trimmedValue) {
      onChange(trimmedValue);
    }
  };

  return (
    <div>
      <label className="block text-md font-medium text-gray-700 mb-2 flex items-center">
        <Phone className="mr-2"  style = {{color:"5e1c57" }}/>
        Phone Number
      </label>
      <input
        type="tel"
        value={value}
        onChange={handlePhoneNumberChange}
        maxLength={maxLength} // Enforce max length at input level
        placeholder={`Enter up to ${maxLength} digits`}
        className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-green-500 focus:border-transparent"
      />
    </div>
  );
};

export default PhoneNumberInput;
